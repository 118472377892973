import { ApolloError, gql, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
  RepeatIcon,
  Descendant,
  QEditor,
  serializeToText,
  Spinner,
  Button,
  InputField,
  TextAreaField,
} from '@qesheq/qesheq-ui'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { Alert, Checkbox, DropdownField, FormCard, ImageUploader, PageHeader } from '../../components'
import { AlertInterface } from '../../components/alert/alert.component'
import { DropdownOptionType } from '../../components/dropdown-field/dropdown-field.component'
import { useInputState } from '../../hooks'
import { ArticleDTO, PageDTO } from '../../providers/graphql/dto'
import { generateUrlKey } from '../../utils'
import './page-details.styles.css'

const pageFields = `
    id
    title
    urlKey
    image
    image
    smallDescription
    description
    dateCreated
    dateModified
    isDeleted
    isPublished
    metaDescription
    metaTitle
    metaImage
    articles{
      value: id
      label: title
    }
`
const PageQuery = gql`
  query Page($id: String!) {
    page(id: $id) {
     ${pageFields}
    }
  }
`

const CreatePageMutation = gql`
  mutation createPage($data: CreatePageInput!) {
    createPage(data: $data) {
      ${pageFields}
    }
  }
`
const UpdatePageMutation = gql`
  mutation updatePage($id: String!, $data: UpdatePageInput!) {
    updatePage(id: $id, data: $data) {
      ${pageFields}
    }
  }
`

const ArticlesQuery = gql`
  query Articles($sort: SortInput, $pagination: PaginationInput, $filters: ArticleFiltersInput) {
    articles(sort: $sort, filters: $filters, pagination: $pagination) {
      total
      limit
      skip
      data {
        value: id
        label: title
        isPublished
      }
    }
  }
`
const ArchivePageMutation = gql`
  mutation archivePages($ids: [ObjectId!]!) {
    archivePages(ids: $ids)
  }
`

const serializeArticles = (
  articles: Array<DropdownOptionType<string>> | null
): Array<Partial<ArticleDTO['id']>> | null => {
  if (!articles) {
    return null
  }
  return articles.map((article) => article.value)
}

const PageDetailsContainer = (): JSX.Element => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [alerts, setAlerts] = useState<Array<AlertInterface>>([])

  const [isPublished, setIsPublished] = useState(false)

  const {
    value: title,
    setValue: setTitle,
    error: titleError,
    validate: validateTitle,
    onBlur: titleOnBlur,
  } = useInputState({ initialValue: '', validation: { required: true } })

  const {
    value: urlKey,
    setValue: setUrlKey,
    error: urlKeyError,
    validate: validateUrlKey,
    onBlur: urlKeyOnBlur,
  } = useInputState({ initialValue: '', validation: { required: true } })

  const {
    value: smallDescription,
    setValue: setSmallDescription,
    error: smallDescriptionError,
    validate: validateSmallDescription,
    onBlur: smallDescriptionOnBlur,
  } = useInputState<string | undefined>({ initialValue: '', validation: {} })

  const {
    value: description,
    setValue: setDescription,
    validate: validateDescription,
  } = useInputState<Descendant[] | undefined>({
    initialValue: undefined,
    validation: {},
  })

  const {
    value: selectedArticles,
    setValue: setSelectedArticles,
    error: articlesError,
    validate: validateArticles,
  } = useInputState<Array<DropdownOptionType<string>> | null>({ initialValue: null, validation: {} })

  const {
    value: image,
    setValue: setImage,
    validate: validateImage,
  } = useInputState<string | null>({ initialValue: null })

  const {
    value: metaTitle,
    setValue: setMetaTitle,
    error: metaTitleError,
    validate: validateMetaTitle,
    onBlur: metaTitleOnBlur,
  } = useInputState<string | undefined>({ initialValue: '' })

  const {
    value: metaDescription,
    setValue: setMetaDescription,
    error: metaDescriptionError,
    validate: validateMetaDescription,
    onBlur: metaDescriptionOnBlur,
  } = useInputState<string | undefined>({ initialValue: '' })

  const {
    value: metaImage,
    setValue: setMetaImage,
    validate: validateMetaImage,
  } = useInputState<string | null>({ initialValue: null })

  const [fetchPage, { loading, error, data: pageData }] = useLazyQuery(PageQuery, {
    variables: {
      id,
    },
    notifyOnNetworkStatusChange: true,
  })

  const { data: articlesData, refetch: ArticlesRefetch } = useQuery(ArticlesQuery, {
    variables: {
      filters: { isPublished: true, title: '' },
      pagination: {
        limit: 20,
      },
    },
    notifyOnNetworkStatusChange: true,
  })

  const [createPage] = useMutation(CreatePageMutation)
  const [updatePage] = useMutation(UpdatePageMutation)
  const [archivePage] = useMutation(ArchivePageMutation)

  const setPageValues = (page: PageDTO): void => {
    setIsPublished(page.isPublished)
    setTitle(page.title)
    setUrlKey(page.urlKey)
    setSmallDescription(page.smallDescription)
    setDescription(page.description)
    setImage(page.image || null)
    setMetaTitle(page.metaTitle)
    setMetaDescription(page.metaDescription)
    setMetaImage(page.metaImage || null)
    setSelectedArticles(page.articles as unknown as DropdownOptionType<string>[] | null)
  }

  useEffect(() => {
    if (id && id !== 'new') {
      fetchPage()
    }
  }, [id])

  useEffect(() => {
    if (pageData?.page) {
      setPageValues(pageData?.page)
    }
  }, [pageData])

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  const handleUrlKeyClick = (): void => {
    setUrlKey(generateUrlKey(title))
  }

  const fetchArticlesOptions = useCallback(
    async (inputValue: string) => {
      const articlesResult = await ArticlesRefetch({
        filters: {
          isPublished: true,
          title: inputValue,
        },
      })
      return articlesResult?.data?.articles?.data || []
    },
    [ArticlesRefetch]
  )

  const handleArchivePage = async (): Promise<void> => {
    if (id) {
      await archivePage({
        variables: {
          ids: [id],
        },
        onCompleted: () => {
          navigate('/pages')
        },
        onError: (err) => {
          setAlerts((prevAlerts) => [
            ...prevAlerts,
            { behavior: 'timed-and-controlled', type: 'error', description: err?.message, time: 5000 },
          ])
        },
      })
    }
  }

  const handleSaveForm = async (): Promise<void> => {
    let isFormValid = true
    isFormValid = validateTitle() && isFormValid
    isFormValid = validateSmallDescription() && isFormValid
    isFormValid = validateDescription() && isFormValid
    isFormValid = validateUrlKey() && isFormValid
    isFormValid = validateImage() && isFormValid
    isFormValid = validateMetaTitle() && isFormValid
    isFormValid = validateMetaDescription() && isFormValid
    isFormValid = validateMetaImage() && isFormValid
    isFormValid = validateArticles() && isFormValid

    if (!isLoading && isFormValid) {
      setIsLoading(true)
      const payload = {
        title,
        urlKey,
        description: description || undefined,
        rawDescription: serializeToText(description || null) || undefined,
        smallDescription: smallDescription || undefined,
        isPublished,
        image: image || undefined,
        metaImage: metaImage || undefined,
        metaTitle: metaTitle || undefined,
        metaDescription: metaDescription || undefined,
        articleIds: serializeArticles(selectedArticles) || undefined,
      }

      let updatedPage: PageDTO
      let submissionError: ApolloError | undefined

      if (id === 'new') {
        const { data } = await createPage({
          variables: {
            data: payload,
          },
          onError: (err) => {
            submissionError = err
          },
        })
        updatedPage = data?.createPage
      } else {
        const { data } = await updatePage({
          variables: {
            id,
            data: payload,
          },
          onError: (err) => {
            submissionError = err
          },
        })
        updatedPage = data?.updatePage
      }

      if (submissionError) {
        setAlerts((prevAlerts) => [
          ...prevAlerts,
          { behavior: 'timed-and-controlled', type: 'error', description: submissionError?.message, time: 5000 },
        ])
      } else if (updatedPage) {
        setAlerts((prevAlerts) => [...prevAlerts, { behavior: 'timed', type: 'success', description: 'تم حفظ المقال' }])
        if (id === 'new') {
          navigate(`/page/${updatedPage.id}`, { replace: true })
        } else {
          setPageValues(updatedPage)
        }
      }

      setIsLoading(false)
    } else {
      setAlerts((prevAlerts) => [
        ...prevAlerts,
        {
          behavior: 'timed-and-controlled',
          type: 'error',
          title: 'راجع الحقول',
          description: `بعض الحقول غير مكتملة أو غير صالحة
        `,
        },
      ])
    }
  }

  return (
    <div className='page-details'>
      <Alert alerts={alerts} updateAlerts={setAlerts} />
      <PageHeader title='صفحة'>
        {id !== 'new' && (
          <Button
            type='button'
            label='حذف'
            layout='solid'
            colorStyle='secondary'
            customClassName='page-header__button'
            isDisabled={isLoading}
            onClick={handleArchivePage}
          />
        )}
        <Button
          type='link'
          label='خروج'
          layout='outline'
          href='/pages'
          colorStyle='secondary'
          customClassName='page-header__button'
          isDisabled={isLoading}
        />
        <Button
          type='button'
          label='حفط'
          layout='solid'
          customClassName='page-header__button'
          isDisabled={isLoading}
          onClick={handleSaveForm}
        />
      </PageHeader>
      <div className='page-details__container'>
        {isLoading && (
          <div className='page-details__spinner-overlay'>
            <Spinner layout='qesheq-boxed' />
          </div>
        )}
        <FormCard
          customClassName='page-details__card page-details__visibility-card'
          title='الرؤية'
          columns={2}
          rowsGap={2}
          columnsGap={2}>
          <Checkbox label='منشور' isChecked={isPublished} onChange={setIsPublished} reversed />
        </FormCard>
        <FormCard
          customClassName='page-details__card page-details__media-card'
          title='الصور'
          columns={2}
          columnsGap={2}>
          <ImageUploader label='الصورة الرئيسية' setImage={setImage} image={image} section='page' location='main' />
        </FormCard>
        <FormCard
          customClassName='page-details__card page-details__info-card'
          title='معلومات'
          columns={2}
          columnsGap={4}
          rowsGap={4}>
          <InputField
            value={title}
            onChange={setTitle}
            errorMessage={titleError}
            label='العنوان'
            onBlur={titleOnBlur}
          />
          <InputField
            value={urlKey}
            onChange={setUrlKey}
            errorMessage={urlKeyError}
            label='مفتاح العنوان'
            onBlur={urlKeyOnBlur}
            icon={<RepeatIcon />}
            iconOnClick={handleUrlKeyClick}
          />
          <InputField
            type='text'
            value={smallDescription}
            onChange={setSmallDescription}
            errorMessage={smallDescriptionError}
            label='ملخص'
            onBlur={smallDescriptionOnBlur}
          />

          <DropdownField
            value={selectedArticles}
            setValue={setSelectedArticles}
            options={articlesData?.tags?.data || []}
            label='مقالات ذات صلة'
            errorMessage={articlesError}
            isClearable
            isMulti
            loadOptions={fetchArticlesOptions}
          />
        </FormCard>
        <FormCard customClassName='page-details__card page-details__description-card' title='النص'>
          <QEditor
            customClassName='page-details__description-editor'
            layout='default'
            imageRole='page:description'
            placeholder='اكتب هنا..'
            value={description}
            setValue={setDescription}
          />
        </FormCard>
        <FormCard
          customClassName='page-details__card page-details__info-card'
          title='بيانات محرك البحث'
          columns={2}
          columnsGap={4}
          rowsGap={4}>
          <InputField
            value={metaTitle}
            onChange={setMetaTitle}
            errorMessage={metaTitleError}
            label='العنوان'
            onBlur={metaTitleOnBlur}
          />
          <ImageUploader
            label='الصورة الرئيسية'
            setImage={setMetaImage}
            image={metaImage}
            section='page'
            location='meta'
          />
          <TextAreaField
            value={metaDescription}
            onChange={setMetaDescription}
            errorMessage={metaDescriptionError}
            label='مفتاح العنوان'
            onBlur={metaDescriptionOnBlur}
          />
        </FormCard>
      </div>
    </div>
  )
}

export default PageDetailsContainer
